<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, responseToOptions } from '@cargill/shared'
import service from '../api/modalService'
import _ from 'lodash'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      ctx.modalTypeOptions = responseToOptions(ctx.modalTypeOptions)

      const modalTypeField = meta.fields.find(
        (field) => field.id == 'modalType'
      )

      modalTypeField.options = ctx.modalTypeOptions

      return meta
    }
    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
